$(window).on('load', function () {
  const ACTION = Object.freeze({ UNREGISTER: 0, REGISTER: 1 });
  var Pages_Project_Good = {
    btn_on: null,
    btn_off: null,
    btn_container: null,
    project_id: null,
    is_logined: false,

    init: function () {
      this.btn_off = $('[data-geenie-project-good="off"]');
      this.btn_on = $('[data-geenie-project-good="on"]');
      this.btn_container = $('[data-geenie-project-id]');
      this.project_id = this.btn_container.data('geenieProjectId');
      this.is_logined = !!$.cookie('is_logined');
      const self = this;
      if (self.is_logined) {
        const promise = Geenie.Good.fetchLikedProjects(self.project_id);
        promise
          .done(function (data) {
            const liked = (data?.pagination?.total ?? 0) > 0;
            let minLikedCount = 0;
            if (liked) {
              self.btn_off.hide();
              self.btn_on.show();
              // 未ログイン時の気になる数同期処理などで、ページキャッシュされた気になる数が0でもこの条件に入ることがあるため、最低カウントを1に設定する
              minLikedCount = 1;
            }
            self.initUsersGoodsCount(0, minLikedCount);
          })
          .fail(function () {
            self.initUsersGoodsCount();
          });
      } else {
        const projectIds = Geenie.Good.get_cookie();
        if (
          projectIds &&
          Geenie.Good.is_already(projectIds, self.project_id)
        ) {
          self.btn_off.hide();
          self.btn_on.show();
          this.initUsersGoodsCount(1);
        } else {
          this.initUsersGoodsCount();
        }
      }

      return this;
    },
    bind: function () {
      this.btn_container
        .find('a')
        .on('click.toggle_status', { self: this }, function (e) {
          var self = e.data.self;
          self.toggle(self.project_id);
          e.preventDefault();
        });
      return this;
    },
    unbind: function () {
      this.btn_container.find('a').off('click.toggle_status');
      return this;
    },
    toggle: function (project_id) {
      var action_num = this.btn_on.is(':visible')
        ? ACTION.UNREGISTER
        : ACTION.REGISTER;
      var self = this.unbind();
      if (
        !self.is_logined &&
        Geenie.Good.is_already(Geenie.Good.get_cookie(), project_id)
      ) {
        self.btn_off.show();
        self.btn_on.hide();
        this.addUsersGoodsCount(-1);
        Geenie.Good.remove_cookie(project_id);
        self.bind();
        return;
      }

      const onError = function () {
        self.bind();
        alert(
          '通信に失敗しました。お手数ですが、少し時間をあけて再度実行してください。',
        );
      };

      // APIへアクセスして、CSRFトークンをCOOKIEに保存後にトグル処理を行う
      window.Geenie.Csrf.fetch_token({
        onSuccess: function (/** @type {{token: string}} */ _csrfData) {
          var promise = Geenie.Good.get_promise_update_regist(
            project_id,
            action_num,
          );
          promise
            .done(function (data) {
              self.is_logined = data.is_logined;
              if (!self.is_logined) {
                var passed = Geenie.Good.set_cookie(project_id);
                if (!passed) {
                  if (
                    window.confirm(
                      '10件以上の気になるを登録するには、会員登録が必要になります',
                    )
                  ) {
                    Geenie.Good.set_cookie(project_id, true);
                    location.href =
                      '/login/?ref=' + encodeURIComponent(location.href);
                  }
                } else {
                  //気になるに追加された時
                  self.btn_off.hide();
                  self.btn_on.show();
                  self.addUsersGoodsCount(1);
                }
              } else {
                if (data.is_already_good) {
                  //気になるに追加された時
                  self.btn_off.hide();
                  self.btn_on.show();
                  self.addUsersGoodsCount(1);
                  if (
                    Geenie.Good.is_already(Geenie.Good.get_cookie(), project_id)
                  ) {
                    Geenie.Good.remove_cookie(project_id);
                  }
                  braze
                    .getUser()
                    .addToCustomAttributeArray(
                      'favorite',
                      String(project_id).toString(),
                    );
                } else {
                  self.btn_off.show();
                  self.btn_on.hide();
                  self.addUsersGoodsCount(-1);
                  braze
                    .getUser()
                    .removeFromCustomAttributeArray(
                      'favorite',
                      String(project_id).toString(),
                    );
                }
              }
              self.bind();
            })
            .fail(onError);
        },
        onError,
      });
    },
    render: function () {},
    usersGoodsCount: 0,
    setUsersGoodsCount: function () {
      const collection = document.getElementsByClassName('users-goods-count');
      for (let i = 0; i < collection.length; i++) {
        const el = collection[i];
        if (el instanceof HTMLElement) {
          el.innerText = this.usersGoodsCount;
        }
      }
    },
    initUsersGoodsCount: function (localCount = 0, minCount = 0) {
      const countValue = Number(
        this.btn_container.data('geenieUsersGoodsCount'),
      );
      const count = !Number.isNaN(countValue) ? countValue : 0;
      this.usersGoodsCount = Math.max(count + localCount, minCount);
      this.setUsersGoodsCount();
    },
    addUsersGoodsCount: function (addedCount = 0) {
      this.usersGoodsCount = this.usersGoodsCount + addedCount;
      this.setUsersGoodsCount();
    },
  };
  Pages_Project_Good.init().bind().render();
});
